import axios from "axios";
import moment from "moment";
import { firebase } from "@/config/firebase";
import { v4 as uuidv4 } from "uuid";

import { Complaint, User, SchoolDistrict, Upload, Error, AppSetting, EmailNotification } from "@/data/interfaces";
import { ComplaintConverter, UserConverter, AppSettingConverter } from "@/data/converters";

export var storage = {
	uploadToFirebase(filepath: string, base64: string): Promise<firebase.storage.UploadTaskSnapshot> {
		return new Promise((resolve, reject) => {
			const storageRef = firebase.storage().ref();

			storageRef
				.child(filepath)
				.putString(base64, "base64")
				.then((task) => {
					resolve(task);
				});
		});
	},

	deleteFromFirebase(filepath: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const storageRef = firebase.storage().ref();

			// Create a reference to the file to delete
			var desertRef = storageRef.child(filepath);

			// Delete the file
			desertRef
				.delete()
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					resolve(false);
				});
		});
	},

	createUpload(file: File): Promise<Upload> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onloadend = () => {
				const newFileObject = {
					lastModified: file.lastModified,
					name: file.name,
					size: file.size,
					type: file.type,
					base64: "",
					url: null,
				} as Upload;

				// convert file to base64 String
				const base64String = reader!.result!.toString().replace("data:", "").replace(/^.+,/, "");
				// store file

				newFileObject.base64 = base64String;

				resolve(newFileObject);
			};

			reader.readAsDataURL(file);
		});
	},

	uploadComplaintDocument(upload: Upload, complaint: Complaint): Promise<Upload> {
		return new Promise(async (resolve, reject) => {
			if (!!upload.base64) {
				let uploadFilepath = complaint.id + "/" + uuidv4() + "/" + upload.name;
				let uploadSnapshot = await storage.uploadToFirebase(uploadFilepath, upload.base64);

				upload.url = await uploadSnapshot.ref.getDownloadURL();
				upload.base64 = null;

				resolve(upload);
			} else {
				reject("Upload does not have a valid base64 value.");
			}
		});
	},

	deleteComplaintDocument(upload: Upload, complaint: Complaint): Promise<boolean> {
		return new Promise(async (resolve, reject) => {
			let uploadFilepath = complaint.id + "/" + upload.name;
			storage.deleteFromFirebase(uploadFilepath);
		});
	},
};

export var data = {
	complaint: {
		collectionName: "complaints",

		add(data: Complaint, quiet = false): Promise<string> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.withConverter(ComplaintConverter)
					.add(data)
					.then((docRef) => {
						resolve(docRef.id);
					});
			});
		},

		update(data: Complaint, quiet = false): void | Promise<string> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(data.id)
					.withConverter(ComplaintConverter)
					.set(data, { merge: true })
					.then(() => {
						if (!quiet) {
							/*
						new AuditLog({
							text: "Ticket Updated",
							summary: "Ticket #" + this.id + " was updated by " + store.getters("appUser")!.getFullName(),
							dataType: "ticket",
							dataId: this.id,
						}).add();
                  */
						}

						resolve(data.id);
					});
			});
		},

		remove() {},

		get(dataId: string): Promise<null | Complaint> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(dataId)
					.withConverter(ComplaintConverter)
					.get()
					.then((doc: firebase.firestore.DocumentSnapshot<Complaint>) => {
						if (doc.exists) {
							resolve(doc.data() as Complaint);
						} else {
							resolve(null);
						}
					})
					.catch((e) => {
						let error = { code: "no_results", message: "Unable to find any complaints", responseCode: 400 } as Error;
						reject(error);
					});
			});
		},
	},

	user: {
		collectionName: "users",

		add(userId: string, data: User, quiet = false): Promise<string> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(userId)
					.withConverter(UserConverter)
					.set(data)
					.then(() => {
						resolve(userId);
					});
			});
		},

		update(data: User, quiet = false): void | Promise<string> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(data.id)
					.withConverter(UserConverter)
					.set(data, { merge: true })
					.then(() => {
						if (!quiet) {
							/*
						new AuditLog({
							text: "Ticket Updated",
							summary: "Ticket #" + this.id + " was updated by " + store.getters("appUser")!.getFullName(),
							dataType: "ticket",
							dataId: this.id,
						}).add();
                  */
						}

						resolve(data.id);
					});
			});
		},

		get(dataId: string): Promise<null | User> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(dataId)
					.withConverter(UserConverter)
					.get()
					.then((doc: firebase.firestore.DocumentSnapshot<User>) => {
						if (doc.exists) {
							resolve(doc.data() as User);
						} else {
							reject(null);
						}
					})
					.catch((e) => {
						let error = { code: "no_results", message: "Unable to find user", responseCode: 400 } as Error;
						reject(error);
					});
			});
		},
	},

	schoolDistrict: {
		async get() {
			let lookBack = 1;
			let schoolDistricts = [];

			while (schoolDistricts.length <= 0) {
				const lastyear = moment().subtract(lookBack, "year").format("Y");
				const endpoint = "https://datastore.geaugaconnected.com/wp-json/wp/v2/gca_tax_rate?tax_rate_year_slug=" + lastyear + "&per_page=100";
				const response = await fetch(endpoint);

				schoolDistricts = await response.json();

				lookBack++;
			}

			return schoolDistricts.sort((a: SchoolDistrict, b: SchoolDistrict) => {
				if (a.metadata.gca_tax_rate_district_number < b.metadata.gca_tax_rate_district_number) return -1;
				if (a.metadata.gca_tax_rate_district_number > b.metadata.gca_tax_rate_district_number) return 1;
				return 0;
			});
		},
	},

	appSetting: {
		collectionName: "appSettings",

		add(data: AppSetting, quiet = false): Promise<string> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.withConverter(AppSettingConverter)
					.add(data)
					.then((docRef) => {
						resolve(docRef.id);
					});
			});
		},

		update(data: AppSetting, quiet = false): void | Promise<string> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(data.id)
					.withConverter(AppSettingConverter)
					.set(data, { merge: true })
					.then(() => {
						if (!quiet) {
							/*
						new AuditLog({
							text: "Ticket Updated",
							summary: "Ticket #" + this.id + " was updated by " + store.getters("appUser")!.getFullName(),
							dataType: "ticket",
							dataId: this.id,
						}).add();
                  */
						}

						resolve(data.id);
					});
			});
		},

		remove() {},

		get(dataId: string): Promise<null | AppSetting> {
			console.log(dataId);
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.doc(dataId)
					.withConverter(AppSettingConverter)
					.get()
					.then((doc: firebase.firestore.DocumentSnapshot<AppSetting>) => {
						if (doc.exists) {
							resolve(doc.data() as AppSetting);
						} else {
							resolve(null);
						}
					})
					.catch((e) => {
						let error = { code: "no_results", message: "Unable to find any matching app settings", responseCode: 400 } as Error;
						reject(error);
					});
			});
		},

		getByKey(key: string): Promise<null | AppSetting> {
			return new Promise((resolve, reject) => {
				firebase
					.firestore()
					.collection(this.collectionName)
					.where("key", "==", key)
					.withConverter(AppSettingConverter)
					.get()
					.then((snapshot: firebase.firestore.QuerySnapshot<AppSetting>) => {
						if (!snapshot.empty) {
							const docs = snapshot.docs.map((doc) => doc.data());
							resolve(docs[0]);
						} else {
							resolve(null);
						}
					});
			});
		},
	},
};

export var notifications = {
	email(data: EmailNotification, headers: Array<string> = []) {
		console.log(data);
		axios.post(process.env.VUE_APP_EMAIL_API, data).then((resp) => {});
	},
};

export var auth = {
	getCurrentUser(): Promise<null | firebase.User> {
		return new Promise((resolve, reject) => {
			const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
				unsubscribe();
				resolve(user);
			}, reject);
		});
	},
};

export var helpers = {
	icons: {
		getFileTypeIcon(file: File | Upload): string {
			let iconClass = "fa-file";

			switch (file.type) {
				case "image/png":
				case "image/jpeg":
					iconClass = "fa-file-image";
					break;
				case "application/pdf":
					iconClass = "fa-file-pdf";
					break;
			}

			return iconClass;
		},
	},

	format: {
		decode: function (html: string) {
			const txt = document.createElement("textarea");
			txt.innerHTML = html;
			return txt.value;
		},

		currency(val: string, round = false) {
			let num = parseFloat(val);

			if (round) {
				num = Math.round(num);
			}

			const numStr = num.toFixed(2);

			const parts = numStr.toString().split(".");
			parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			return "$" + parts.join(".");
		},

		parcelId(f: string) {},

		titleCase(str: string): string {
			return str
				.toLowerCase()
				.split(" ")
				.map(function (word) {
					return word.replace(word[0], word[0].toUpperCase());
				})
				.join(" ");
		},

		bytes(bytes: number, decimals = 2 as number): string {
			if (bytes === 0) return "0 Bytes";

			const k = 1024;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

			const i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
		},

		sanitize(str: string): string {
			str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "");
			return str.trim();
		},
	},

	math: {
		preciseRound(number: number, decimalPlaces = 2): number {
			const tenFactor = Math.pow(10, decimalPlaces);
			return Math.round(number * tenFactor) / tenFactor;
		},
	},

	diff(obj1: any, obj2: any) {
		// Make sure an object to compare is provided
		if (!obj2 || Object.prototype.toString.call(obj2) !== "[object Object]") {
			return obj1;
		}

		//
		// Variables
		//

		const diffs = {} as any;
		let key;

		//
		// Methods
		//

		/**
		 * Check if two arrays are equal
		 * @param  {Array}   arr1 The first array
		 * @param  {Array}   arr2 The second array
		 * @return {Boolean}      If true, both arrays are equal
		 */
		const arraysMatch = function (arr1: Array<any>, arr2: Array<any>) {
			// Check if the arrays are the same length
			if (arr1.length !== arr2.length) return false;

			// Check if all items exist and are in the same order
			for (let i = 0; i < arr1.length; i++) {
				if (arr1[i] !== arr2[i]) return false;
			}

			// Otherwise, return true
			return true;
		};

		/**
		 * Compare two items and push non-matches to object
		 * @param  {*}      item1 The first item
		 * @param  {*}      item2 The second item
		 * @param  {String} key   The key in our object
		 */
		const compare = function (item1: any, item2: any, key: string) {
			// Get the object type
			const type1 = Object.prototype.toString.call(item1);
			const type2 = Object.prototype.toString.call(item2);

			// If type2 is undefined it has been removed
			if (type2 === "[object Undefined]") {
				diffs[key] = null;
				return;
			}

			// If items are different types
			if (type1 !== type2) {
				diffs[key] = item2;
				return;
			}

			// If an object, compare recursively
			if (type1 === "[object Object]") {
				const objDiff = helpers.diff(item1, item2);
				if (Object.keys(objDiff).length > 0) {
					diffs[key] = objDiff;
				}
				return;
			}

			// If an array, compare
			if (type1 === "[object Array]") {
				if (!arraysMatch(item1, item2)) {
					diffs[key] = item2;
				}
				return;
			}

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (type1 === "[object Function]") {
				if (item1.toString() !== item2.toString()) {
					diffs[key] = item2;
				}
			} else {
				if (item1 !== item2) {
					diffs[key] = item2;
				}
			}
		};

		//
		// Compare our objects
		//

		// Loop through the first object
		for (key in obj1) {
			if (obj1.hasOwnProperty(key)) {
				compare(obj1[key], obj2[key], key);
			}
		}

		// Loop through the second object and find missing items
		for (key in obj2) {
			if (obj2.hasOwnProperty(key)) {
				if (!obj1[key] && obj1[key] !== obj2[key]) {
					diffs[key] = obj2[key];
				}
			}
		}

		// Return the object of differences
		return diffs;
	},
};

export var log = function (...arguements: any) {
	if (process.env.NODE_ENV === "development") {
		const args = Array.prototype.slice.call(arguments);
		args.unshift("%cBoard of Revisions App:", "color: tomato");
		console.log.apply(console, args as any);
	}
};
